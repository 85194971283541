import { types, getRoot, getEnv, flow, getParent } from "mobx-state-tree";

const checkoutStateOptions = [
  "initiated",
  "committed",
  "confirmed",
  "confirmStarted",
  "abandoned",
  "failed"
];

const orderStatusOptions = [
  "none",
  "created",
  "canceled",
  "returned",
  "shipped"
];

const paymentStatusOptions = [
  "none",
  "authorized",
  "voided",
  "settled",
  "confirmed",
  "refunded",
  "failed"
];

const BasketItem = types
  .model("BasketItem", {
    articleNumber: types.identifier,
    groupId: types.maybeNull(types.string),
    pricePerItem: types.number,
    quantity: types.integer,
    totalPrice: types.number
  })
  .views(self => ({
    get shippedQuantity() {
      const lineItem = getParent(self, 3).shippedItems.find(
        i => i.articleNumber === self.articleNumber
      );

      return lineItem ? lineItem.quantity : 0;
    }
  }));

const Basket = types.model("Basket", {
  items: types.array(BasketItem)
});

const LineItem = types.model("LineItem", {
  articleNumber: types.identifier,
  quantity: types.number
});

const Employee = types.model("Employee", {
  name: types.maybeNull(types.string)
});

export default types
  .model("Checkout", {
    id: types.identifier,
    externalOrderId: types.maybe(types.string),
    totalPrice: types.number,
    currencyCode: types.string,
    deliveryCountryCode: types.string,
    languageCode: types.string,
    createdAtUtc: types.string,
    confirmedAtUtc: types.maybeNull(types.string),
    screenId: types.string,
    sessionId: types.string,
    handlerCode: types.string,
    errorMessage: types.maybe(types.string),
    state: types.enumeration(checkoutStateOptions),
    orderStatus: types.enumeration(orderStatusOptions),
    paymentStatus: types.enumeration(paymentStatusOptions),
    employee: types.maybeNull(Employee),
    shippingList: types.optional(types.map(types.number), {}),
    shippedItems: types.optional(types.array(LineItem), []),
    basket: Basket
  })
  .views(self => ({
    get screen() {
      return getRoot(self).screens.items.get(self.screenId);
    },
    get session() {
      return getRoot(self).sessions.get(self.sessionId);
    },
    get totalItemQuantity() {
      return self.basket.items.reduce((result, item) => {
        result += item.quantity;
        return result;
      }, 0);
    },
    get totalShippedItemQuantity() {
      return self.basket.items.reduce((result, item) => {
        result += item.shippedQuantity;
        return result;
      }, 0);
    },
    get canShipItems() {
      return (
        self.orderStatus === "created" && self.paymentStatus === "authorized"
      );
    },
    get canReturnItems() {
      return self.orderStatus === "shipped";
    },
    get canCancelOrder() {
      return self.orderStatus === "created";
    },
    get hasError() {
      return self.errorMessage !== null && self.errorMessage !== undefined;
    },
    get status() {
      const { hasError, state, orderStatus, paymentStatus } = self;

      let level = "unknown";

      if (state === "confirmed") {
        level = "ok";
      }

      const isPaymentLikely =
        paymentStatus === "authorized" ||
        paymentStatus === "confirmed" ||
        paymentStatus === "settled";

      const didSucceed =
        (orderStatus === "created" || orderStatus === "shipped") &&
        isPaymentLikely;

      const didFail =
        hasError ||
        state === "failed" ||
        orderStatus === "failed" ||
        paymentStatus === "failed";

      if (didSucceed) {
        level = "ok";
      } else if (didFail) {
        level = "error";
      } else if (paymentStatus === "voided") {
        level = "warning";
      }

      return level;
    }
  }))
  .actions(self => ({
    updateEmployee(name) {
      const { connector } = getEnv(getRoot(self));

      const {
        cancellationToken,
        cancel
      } = connector.getCancellationTokenSource();

      const employee = { name };

      const action = flow(function* updateEmployee() {
        try {
          self.employee = employee;

          yield connector.updateOrder(
            self.id,
            { employeeDetails: employee },
            cancellationToken
          );

          if (name) {
            getRoot(self).createNotification(
              `Staff has been changed to ${name}`
            );
          } else {
            getRoot(self).createNotification("Staff has been removed");
          }
        } catch (err) {
          // TODO: Rollback
          throw err;
        }
      });

      return getRoot(self).runAction(
        `updateCheckoutEmployee-{${self.id}}`,
        action,
        cancel
      );
    }
  }));
