import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Text, Stack, Box } from "grommet";

class Metric extends React.Component {
  render() {
    const {
      value,
      unit = "",
      align = "right",
      size = "medium",
      placeholder = "",
      plain = false
    } = this.props;

    const textAlign = align === "right" ? "end" : "start";
    const renderUnit = unit ? (
      <Text size="small" margin={{ left: "xsmall" }}>
        {unit}
      </Text>
    ) : null;

    let renderValue;
    let diameter = 40;
    const maxDiameter = 94;

    if (typeof value === "string") {
      renderValue = value;
    } else if (value !== null && value !== undefined) {
      renderValue = Math.round(value);
      diameter = Math.min(maxDiameter, diameter + renderValue / 5);
    } else {
      renderValue = placeholder;
    }

    return (
      <Box
        align="center"
        direction="row"
        alignSelf="center"
        style={{ opacity: !plain && value === 0 ? 0 : 1 }}
      >
        <Stack anchor="center">
          {plain ? null : (
            <Box
              width={`${diameter}px`}
              height={`${diameter}px`}
              round={`${diameter / 2}px`}
              alignSelf="center"
              background="light-2"
            />
          )}
          <Text textAlign={textAlign} alignSelf="center" size={size}>
            {renderValue}
          </Text>
        </Stack>
        {renderUnit}
      </Box>
    );
  }
}

Metric.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.string,
  size: PropTypes.string,
  plain: PropTypes.bool
};

export default observer(Metric);
