import React from "react";
import ReactDOM from "react-dom";
import { Grommet } from "grommet";
import { BrowserRouter, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//import registerServiceWorker from './registerServiceWorker';

import StaffPortal from "./roots/StaffPortal";

import "./index.css";

const theme = {
  global: {
    colors: {
	  brand: "#039cdb",
	  "status-unknown": "#f1f1f1"
    },
    font: {
      family: "din-2014, sans-serif",
      weight: 600
    },
    size: {
      "metric-cell": "116px"
    }
  },
  layer: {
    overlay: {
      background: "rgba(242, 242, 242, 0.9)"
    }
  },
  button: {
    padding: { horizontal: "28px" },
    extend: props => `
		font-family: "din-2014";
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;
		letter-spacing: 0.02em;`
  },
  select: {
    control: {
      extend: props => `
		padding-left: 5px;`
    }
  },
  heading: {
    extend: props => `
    line-height: 1em;
		margin: 0`,
    level: {
      6: {
        font: {
          weight: 700,
        },
      }
    }
  }
};

ReactDOM.render(
  <BrowserRouter>
    <Grommet theme={theme}>
      <Route path="/" component={StaffPortal} />
      <ToastContainer />
    </Grommet>
  </BrowserRouter>,
  document.getElementById("root")
);

//registerServiceWorker();
