/* @flow weak */

import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

const svgPath =
  "M17.1861032,16.5 L32.6569484,1.02915476 L33,0.686103174 L32.3138968,0" +
  "L31.9708452,0.343051587 L16.5,15.8138968 L1.02915476,0.343051587 L0.686103174,0" +
  "L3.55271368e-15,0.686103174 L0.343051587,1.02915476 L15.8138968,16.5 L0.343051587,31.9708452" +
  "L0,32.3138968 L0.686103174,33 L1.02915476,32.6569484 L16.5,17.1861032 L31.9708452,32.6569484" +
  "L32.3138968,33 L33,32.3138968 L32.6569484,31.9708452 L17.1861032,16.5 Z";

class CloseButton extends React.Component {
  render() {
    const { onPress } = this.props;

    return (
      <button
        onClick={onPress}
        style={{ background: "transparent", margin: 0, padding: 0, border: "none" }}
      >
        <svg width="50px" height="50px" viewBox="0 0 33 33" version="1.1">
          <g id="Cross">
            <path d={svgPath} id="Line" />
          </g>
        </svg>
      </button>
    );
  }
}

CloseButton.propTypes = {
  onPress: PropTypes.func.isRequired
};

export default observer(CloseButton);
