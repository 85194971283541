import React, { Component } from "react";
import { Form, TextInput } from "grommet";
import { observer } from "mobx-react";
import PropTypes from "prop-types";

class SuggestionsInput extends Component {
  constructor(props) {
    super(props);

    this.state = { value: null };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    this.setState({ value: this.props.value });
  }

  componentWillReceiveProps({ value }) {
    this.setState({ value });
  }

  handleChange(e) {
    this.setState({ value: e.target.value, hasChange: true });
  }

  handleSubmit() {
    if (!this.props.onChange) {
      return;
    }

    let { value } = this.state;

    if (value === "") {
      value = null;
    }

    this.props.onChange(value);
    this.setState({ value });
  }

  handleSelect(e) {
    if (!this.props.onChange) {
      return;
    }

    this.props.onChange(e.suggestion);
  }

  render() {
    const { suggestions, placeholder } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <TextInput
          value={this.state.value || ""}
          dropHeight="small"
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          placeholder={placeholder}
          suggestions={suggestions}
        />
      </Form>
    );
  }
}

SuggestionsInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.string)
};

export default observer(SuggestionsInput);
