import React from "react";
import { observer } from "mobx-react";

import ensureStore from "../store";
import Keypad from "../components/Keypad";
//import Background from "../components/Background";

//import "./LockScreen.css";

class LockScreen extends React.Component {
  constructor(props) {
    super(props);

    this.handleInput = this.handleInput.bind(this);
    this.state = { pinCode: "----" };
    this.store = ensureStore();
  }

  handleInput(value) {
    const newPinCode = `${this.state.pinCode.slice(1)}${value}`;

    this.setState({ pinCode: newPinCode }, () => {
      this.store.tryUnlock(newPinCode);
    });
  }

  render() {
    //const { lockBackgroundUrls, lockBackgroundColor} = this.store;

    return (
      <div className="centered-container">
        <Keypad onInput={this.handleInput} />
      </div>
    );
  }
}

export default observer(LockScreen);
