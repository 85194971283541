import React from "react";
import PropTypes from "prop-types";

class KeypadButton extends React.Component {
  onClick(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.props.isDisabled) {
      this.props.onPress();
    }
  }

  render() {
    const { children, isDisabled, style } = this.props;

    return (
      <button
        style={style}
        onClick={this.onClick.bind(this)}
        disabled={isDisabled}
      >
        {children}
      </button>
    );
  }
}

KeypadButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.string
};

export default KeypadButton;
