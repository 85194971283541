import moment from "moment";
import { types } from "mobx-state-tree";

export default types
  .model("Heartbeat", {
    id: types.string,
    customerId: types.string,
    screenId: types.identifier,
    screenName: types.string,
    timestamp: types.string,
    appVersion: types.maybe(types.string),
    displayResolution: types.maybe(types.string),
    displayScaling: types.maybe(types.number),
    displaySize: types.maybe(types.number),
    geopositionAccuracy: types.maybe(types.number),
    geopositionAltitude: types.maybe(types.number),
    geopositionHash: types.maybe(types.string),
    geopositionLatitude: types.maybe(types.number),
    geopositionLongitude: types.maybe(types.number),
    geopositionPositionSource: types.maybe(types.string),
    inventorySynchronizationActive: types.maybe(types.boolean),
    notProcessedItemsSynchronizationActive: types.maybe(types.boolean),
    networkRttMs: types.maybe(types.number),
    networkType: types.maybe(types.string),
    productsNotProcessedCount: types.maybe(types.number),
    productsReadyEnabledCount: types.maybe(types.number),
    ramBytesUsed: types.maybe(types.number),
    sessionId: types.maybe(types.string),
    storageBytesUsed: types.maybe(types.number),
    storagePercentageUsed: types.maybe(types.number),
    screenIsDemo: types.boolean,
    userSessionActive: types.maybe(types.boolean),
    windowsVersion: types.maybe(types.string)
  })
  .views(self => ({
    get isOnline() {
      return moment(self.timestamp).isAfter(moment().subtract(5, "minute"));
    },
    get hasActiveSession() {
      return self.isOnline && self.userSessionActive;
    },
    get roundedStoragePercentageUsed() {
      return Math.round(self.storagePercentageUsed * 100);
    },
    get sortName() {
      return self.screenName.toLowerCase();
    },
    get hasWarning() {
      return self.storagePercentageUsed > 0.85;
    },
    get lastPingMinutes() {
      const nowMoment = moment();
      const heartbeatMoment = moment(self.timestamp);

      return moment.duration(nowMoment.diff(heartbeatMoment)).asMinutes();
    }
  }));
