import qs from "qs";
import React from "react";
//import { autorun } from "mobx";
import { observer } from "mobx-react";

import ensureStore from "../store";
import LockScreen from "../containers/LockScreen";
import Insights from "../containers/Insights";
import Checkout from "../containers/Checkout";
import Footer from "../containers/Footer";

class StaffPortal extends React.Component {
  constructor(props) {
    super(props);

    this.store = ensureStore(props.store);
  }

  componentDidMount() {
    const { token, noLock } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    if (token) {
      this.store.identity.signIn(token);
    }

    if (noLock === 'true') {
      this.store.setIsLocked(false);
    }
  }

  render() {
    // Lock
    if (this.store.isLocked) {
      return <LockScreen />;
    }

    return (
      <div className="container">
        <Insights />
        <Checkout />
        <Footer />
      </div>
    );
  }
}

export default observer(StaffPortal);
