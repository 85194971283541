import logstash from "logstash";
import moment from "moment";
import { toast } from "react-toastify";

import { types, getEnv, getSnapshot, flow } from "mobx-state-tree";

import Identity from "./models/identity";
import Screens from "./models/screens";
import Stats from "./models/stats";
import Products from "./models/products";
import Checkouts from "./models/checkouts";

import createConnector from "../lib/connector";
import mapToList from "../lib/mapToList";

let store = null;
let actionCancellationMap = new Map();
const useOfflineMode = process.env.REACT_APP_OFFLINE_MODE === "true";

// Set start of week to Monday
moment.updateLocale("en", {
  week: {
    dow: 1
  }
});

const connector = createConnector({
  auth: {
    tokenSecret: process.env.REACT_APP_IDENTITY_TOKEN_SECRET,
    scopes: process.env.REACT_APP_IDENTITY_SCOPES,
    clientId: process.env.REACT_APP_IDENTITY_CLIENT_ID,
    clientSecret: process.env.REACT_APP_IDENTITY_CLIENT_SECRET,
    tokenUrl: process.env.REACT_APP_IDENTITY_TOKEN_URL
  },
  customerApiUrl: process.env.REACT_APP_CUSTOMER_API_URL,
  importApiUrl: process.env.REACT_APP_IMPORT_API_URL,
  portalApiUrl: process.env.REACT_APP_PORTAL_API_URL,
  insightsApiUrl: process.env.REACT_APP_INSIGHTS_API_URL,
  moneyApiUrl: process.env.REACT_APP_MONEY_API_URL,
  mailApiUrl: process.env.REACT_APP_MAIL_API_URL
});

const logger = useOfflineMode
  ? console
  : logstash(process.env.REACT_APP_LOGSTASH_URL, ["portal", "vendo"]);

// TODO: tokenSecret should not be part of the client side app
// It should use encrypt/decrypt credentials from Insights API
const PortalStore = types
  .model("PortalStore", {
    isLocked: true,
    pinCode: "2486",
    showDemoScreens: false,
    selectedCheckoutId: types.maybeNull(types.string),
    identity: types.optional(Identity, {}),
    checkouts: types.optional(Checkouts, {}),
    screens: types.optional(Screens, {}),
    products: types.optional(Products, {}),
    stats: types.optional(Stats, {}),
    toDate: types.optional(types.string, new Date().toISOString()),
    fromDate: types.optional(
      types.string,
      moment()
        .subtract(1, "month")
        .toISOString()
    ),
    pendingActions: types.optional(types.map(types.boolean), {})
  })
  .views(self => ({
    get customerId() {
      if (!self.identity.user) {
        return null;
      }

      return self.identity.user.customerId;
    },
    get localeOptions() {
      // TODO: Get available locales directly from API
      const options = self.products.items.length
        ? self.products.items[0].localeOptions
        : [];
      return options.map(({ key, label }) => ({ label, value: key }));
    },
    get currencyOptions() {
      // TODO: Get available currencies directly from API
      const options = self.products.items.length
        ? self.products.items[0].currencyOptions
        : [];
      return options.map(({ key, label }) => ({ label, value: key }));
    },
    get pendingActionCount() {
      const actionList = mapToList(self.pendingActions, true);
      return actionList.length;
    },
    get hasPendingActions() {
      return self.pendingActionCount > 0;
    },
    get selectedCheckout() {
      if (!self.selectedCheckoutId) {
        return null;
      }

      return self.checkouts.items.get(self.selectedCheckoutId);
    }
  }))
  .actions(self => ({
    reset() {
      for (const [actionName, cancel] of actionCancellationMap) {
        console.debug(`Cancelling pending action [${actionName}]`);
        cancel();
      }

      self.selectedCheckoutId = null;
      self.pendingActions = {};
      self.isLocked = true;

      self.identity.reset();
      self.checkouts.reset();
      self.screens.reset();
      self.stats.reset();
    },
    handleError(err, message) {
      self.createNotification(`${message} - ${err.message}`, "error");
      const meta = self.user ? getSnapshot(self.user) : {};
      getEnv(self).logger.error(err, meta);
    },
    setFromDate(fromDate) {
      self.fromDate = moment(fromDate)
        .startOf("day")
        .toISOString();
    },
    setToDate(toDate) {
      self.toDate = moment(toDate)
        .endOf("day")
        .toISOString();
    },
    setSelectedCheckoutId(checkoutId) {
      self.selectedCheckoutId = checkoutId;
    },
    createNotification(message, level = "default") {
      const type = toast.TYPE[level.toUpperCase()];

      toast(message, { type });
    },
    tryUnlock(pinCode) {
      self.setIsLocked(pinCode !== self.pinCode);
    },
    setIsLocked(isLocked) {
      self.isLocked = isLocked === true;
    },

    /**
     * Runs an action
     * actionName {string}
     * action {function}
     * cancel {function}
     */
    runAction: flow(function* runAction(
      actionName,
      action,
      cancel,
      throwExceptions = false
    ) {
      const existingAction = self.pendingActions.get(actionName);
      const cancelExistingAction = actionCancellationMap.get(actionName);

      if (cancelExistingAction) {
        cancelExistingAction();
      } else if (existingAction) {
        return;
      }

      self.pendingActions.set(actionName, true);
      actionCancellationMap.set(actionName, cancel);

      try {
        yield action();
      } catch (err) {
        if (err.message === getEnv(self).connector.REQUEST_CANCELLED) {
          return;
        }

        if (err.message === getEnv(self).connector.ACCESS_TOKEN_EXPIRED) {
          console.debug(`Access token expired [${actionName}]`);
          return;
        }

        if (throwExceptions) {
          throw err;
        }

        self.handleError(err, `Could not run action ${actionName}`);
      } finally {
        self.pendingActions.delete(actionName);
        actionCancellationMap.delete(actionName);
      }
    })
  }));

export default function ensureStore() {
  if (store !== null) {
    return store;
  }

  store = PortalStore.create({}, { connector, logger });

  connector.auth.events.on("onSignedIn", user => {
    store.identity.setUser(user);

    setImmediate(() => {
      store.screens.fetch();
      store.screens.fetchLatestHeartbeats();
      store.checkouts.fetch();
      store.stats.fetchSessionStats();
      store.stats.fetchHistogram();
      store.stats.fetchCheckoutStats();
    });
  });
  connector.auth.events.on("onSignedOut", store.reset);
  connector.auth.tryRestoreAccessToken();

  return store;
}
