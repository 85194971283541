import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Box } from "grommet";

class MetricRow extends React.Component {
  render() {
    let { children } = this.props;

    if (!children) {
      return null;
    }

    const cells = children.map((child, i) => {
      return (
        <Box
          height="58px"
          width="metric-cell"
          justify="center"
          verticalAlign="middle"
          style={{ overflow: "visible" }}
          key={i}
        >
          {child}
        </Box>
      );
    });

    return (
      <Box direction="row">
        {cells}
      </Box>
    );
  }
}

MetricRow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object)
};

export default observer(MetricRow);
