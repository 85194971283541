/* @flow weak */

import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { PulseLoader } from "react-spinners";
import { Box } from "grommet";

class LoadingIndicator extends React.Component {
  render() {
    const { isLoading, size = 6, color = "#FFF" } = this.props;

    if (isLoading === undefined || isLoading === null) {
      return null;
    }

    return (
      <Box height={`${5 * size}px`} justify="center">
        {isLoading ? <PulseLoader size={size} color={color} /> : null}
      </Box>
    );
  }
}

LoadingIndicator.propTypes = {
  isLoading: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string
};

export default observer(LoadingIndicator);
