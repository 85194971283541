import AES from 'crypto-js/aes';
import cryptoEncUtf8 from 'crypto-js/enc-utf8';
import { decode } from 'url-safe-base64';
import jwtDecode from 'jwt-decode';
import ClientOAuth2 from 'client-oauth2';
import EventEmitter from 'wolfy87-eventemitter';
import errors from './errors';

function create(options) {
  return new Auth(options);
}

function Auth(options) {
  this.client = new ClientOAuth2({
    clientId: options.clientId,
    clientSecret: options.clientSecret,
    accessTokenUri: options.tokenUrl,
    scopes: options.scopes ? options.scopes.split(",") : []
  });

  this.events = new EventEmitter();
  this.tokenSecret = options.tokenSecret;
  this.accessToken = null;
  this.user = null;
}

Auth.prototype.signIn = async function signIn(token) {
	if (!token) {
		throw new TypeError('Invalid token');
	}

	try {
    const bytes = AES.decrypt(decode(token), this.tokenSecret);

		const [ email, password ] = bytes.toString(cryptoEncUtf8).split(':');
		const { accessToken } = await this.client.owner.getToken(email, password);

		this.parseAccessToken(accessToken);
		this.tryStoreAccessToken();
	} catch (err) {
		if (err.code === 'EAUTH') {
			throw new Error('Invalid token');
		}

		throw new Error('An unexpected error occured');
	}
};

Auth.prototype.signOut = async function signOut() {
  this.user = null;
  this.accessToken = null;
  this.accessTokenExp = null;
  this.tryClearStoredAccessToken();

  this.events.emit("onSignedOut");
};

Auth.prototype.parseAccessToken = function parseAccessToken(accessToken) {
  const { sub, exp, email, customer_id } = jwtDecode(accessToken);

  if (exp <= Math.floor(Date.now() / 1000)) {
    console.debug(`Stored access token has expired [${email}] [${exp}]`);
    this.events.emit("onAccessTokenExpired");
    this.tryClearStoredAccessToken();
    return;
  }

  this.user = {
    email,
    id: sub,
    customerId: customer_id || null,
    isAdmin: customer_id === null || customer_id === undefined
  };

  console.debug(`Parsed access token for [${email}]`);

  this.accessTokenExp = exp;
  this.accessToken = accessToken;
  this.events.emit("onSignedIn", this.user);
};

Auth.prototype.tryStoreAccessToken = function tryStoreAccessToken() {
  console.debug("Trying to store access token");

  if (!window) {
    return;
  }

  try {
    window.localStorage.setItem("accessToken", this.accessToken);
  } catch (err) {
    console.error(err);
  }
};

Auth.prototype.tryRestoreAccessToken = function tryRestoreAccessToken() {
  console.debug("Trying to restore access token");

  if (!window) {
    return;
  }

  try {
    const accessToken = window.localStorage.getItem("accessToken");
    this.parseAccessToken(accessToken);
  } catch (err) {
    console.debug(err);
  }
};

Auth.prototype.tryClearStoredAccessToken = function tryClearStoredAccessToken() {
  console.debug("Trying to clear stored access token");

  if (!window) {
    return;
  }

  try {
    window.localStorage.removeItem("accessToken");
  } catch (err) {
    console.error(err);
  }
};

Auth.prototype.isAccessTokenExpired = function isAccessTokenExpired() {
  const now = Math.floor(Date.now() / 1000);
  return this.accessToken && this.accessTokenExp < now;
};

Auth.prototype.ensureValidAccessToken = function ensureValidAccessToken() {
  if (this.isAccessTokenExpired()) {
    this.signOut();

    throw new Error(errors.ACCESS_TOKEN_EXPIRED);
  }
};

export default create;
