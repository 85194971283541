import moment from "moment";
import { types, getRoot, getEnv, flow, getParent } from "mobx-state-tree";

import Setting from "./setting";

export default types
  .model("Screen", {
    id: types.identifier,
    name: types.string,
    deviceName: types.string,
    settings: types.map(Setting)
  })
  .views(self => ({
    get isDemo() {
      const isDemoSetting = self.settings.get("isDemo");
      return isDemoSetting ? isDemoSetting.value : false;
    },

    get storeId() {
      const storeIdSetting = self.settings.get("storeId");
      return storeIdSetting ? storeIdSetting.value : null;
    },

    get sortName() {
      return self.name.toLowerCase();
    },

    /*
    get historgramStats() {
      return getRoot(self).stats.histogram.get(self.id);
    },*/

    get sessions() {
      return getRoot(self).sessions.sortedItems.filter(
        s => s.screenId === self.id
      );
    },

    get sessionStats() {
      return getRoot(self).stats.screenSessions.get(self.id);
    },

    get checkoutStats() {
      return getRoot(self).stats.screenCheckouts.get(self.id);
    },

    get sessionCount() {
      return self.sessionStats ? self.sessionStats.all.count : 0;
    },

    get basketCount() {
      return self.sessionStats ? self.sessionStats.all.hasBasket : 0;
    },

    get checkoutCount() {
      return self.checkoutStats ? self.checkoutStats.confirmed.count : 0;
    },

    get orderCount() {
      return self.checkoutStats ? self.checkoutStats.ordered.count : 0;
    },

    get failedCheckoutCount() {
      return self.checkoutStats ? self.checkoutStats.failed.count : 0;
    },

    get heartbeat() {
      return getParent(self, 2).heartbeats.get(self.id);
    },

    get isOnline() {
      if (!self.heartbeat) {
        return false;
      }

      return moment(self.heartbeat.timestamp).isAfter(
        moment().subtract(5, "minute")
      );
    },

    get networkRttMs() {
      if (!self.heartbeat) {
        return null;
      }

      return self.heartbeat.networkRttMs;
    },

    get storagePercentageUsed() {
      if (!self.heartbeat) {
        return 0;
      }

      return self.heartbeat.storagePercentageUsed * 100;
    },

    get displaySize() {
      if (!self.heartbeat) {
        return null;
      }

      return self.heartbeat.displaySize
        ? Math.round(self.heartbeat.displaySize)
        : null;
    },

    get appVersion() {
      if (!self.heartbeat) {
        return null;
      }

      return self.heartbeat.appVersion;
    },

    get isSynchronizationActive() {
      if (!self.heartbeat || !self.isOnline) {
        return false;
      }

      return (
        self.heartbeat.inventorySynchronizationActive ||
        self.heartbeat.notProcessedItemsSynchronizationActive
      );
    },

    get hasActiveSession() {
      if (!self.heartbeat || !self.isOnline) {
        return false;
      }

      return self.heartbeat.hasActiveSession;
    },

    get hasWarning() {
      return !self.heartbeat;
    },

    get status() {
      let status = "unknown";

      if (self.isOnline) {
        status = "ok";
      }

      if (self.hasWarning) {
        status = "warning";
      }

      return status;
    }
  }))
  .actions(self => ({
    updateName: flow(function* updateName(name) {
      const oldName = self.name;

      try {
        self.name = name;
        yield getEnv(self).connector.updateScreen(self.id, { name });
      } catch (err) {
        self.name = oldName;
        getRoot(self).handleError(err, "Could not rename screen");
      }
    }),
    updateSetting: flow(function* updateSetting(key, value) {
      const setting = self.settings.get(key);
      const oldValue = setting.value;

      try {
        setting.value = value;
        yield getEnv(self).connector.updateSetting(self.id, key, value);
        getRoot(self).createNotification(`Setting ${key} has been changed to ${value}`);
      } catch (err) {
        self.settings.get(key).value = oldValue;
        getRoot(self).handleError(err, `Could not update ${key}`);
      }
    })
  }));
