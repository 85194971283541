/* @flow weak */

import React from "react";
import PropTypes from "prop-types";

import KeypadButton from "./KeypadButton";
import "./Keypad.css";

class Keypad extends React.Component {
  handleInput(value) {
    this.props.onInput(value);
  }
  render() {
    return (
      <div className="keypad">
        <div className="keypad-row">
          <KeypadButton type="round" onPress={() => this.handleInput("1")}>
            <h1>1</h1>
          </KeypadButton>
          <KeypadButton type="round" onPress={() => this.handleInput("2")}>
            <h1>2</h1>
          </KeypadButton>
          <KeypadButton type="round" onPress={() => this.handleInput("3")}>
            <h1>3</h1>
          </KeypadButton>
        </div>
        <div className="keypad-row">
          <KeypadButton type="round" onPress={() => this.handleInput("4")}>
            <h1>4</h1>
          </KeypadButton>
          <KeypadButton type="round" onPress={() => this.handleInput("5")}>
            <h1>5</h1>
          </KeypadButton>
          <KeypadButton type="round" onPress={() => this.handleInput("6")}>
            <h1>6</h1>
          </KeypadButton>
        </div>
        <div className="keypad-row">
          <KeypadButton type="round" onPress={() => this.handleInput("7")}>
            <h1>7</h1>
          </KeypadButton>
          <KeypadButton type="round" onPress={() => this.handleInput("8")}>
            <h1>8</h1>
          </KeypadButton>
          <KeypadButton type="round" onPress={() => this.handleInput("9")}>
            <h1>9</h1>
          </KeypadButton>
        </div>
        <div className="keypad-row">
          <KeypadButton type="round" onPress={() => this.handleInput("0")}>
            <h1>0</h1>
          </KeypadButton>
        </div>
      </div>
    );
  }
}

Keypad.propTypes = {
  onInput: PropTypes.func.isRequired
};

export default Keypad;
