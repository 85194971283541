import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Box, Heading, Text } from "grommet";

import Metric from "./Metric";
import MetricRow from "./MetricRow";
import StatusIndicator from "./StatusIndicator";

class ScreenSummary extends React.Component {
  render() {
    const { screen } = this.props;

    if (!screen) {
      return null;
    }

    const { name, appVersion, status, hasActiveSession } = screen;

    return (
      <Box
        direction="row"
        align="center"
        justify="between"
        background={{ color: "white" }}
        pad={{ left: "medium", right: "xsmall", vertical: "medium" }}
        animation="fadeIn"
        fill="horizontal"
        alignSelf="stretch"
      >
        <Box direction="row" align="center">
          <Box margin={{ right: "medium" }}>
            <StatusIndicator
              status={status}
              hasActivity={hasActiveSession}
            />
          </Box>
          <Box>
            <Heading level={2} size="medium">
              {name}
            </Heading>
            <Text size="small">
              {appVersion ? `Vendo ${appVersion}` : null}
            </Text>
          </Box>
        </Box>
        <MetricRow>
          <Metric value={screen.sessionCount} />
          <Metric value={screen.basketCount} />
          <Metric value={screen.checkoutCount} />
          <Metric value={screen.orderCount} />
          <Metric value={screen.failedCheckoutCount} />
        </MetricRow>
      </Box>
    );
  }
}

ScreenSummary.propTypes = {
  screen: PropTypes.object,
  isLoading: PropTypes.bool
};

export default observer(ScreenSummary);
