import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Select } from "grommet";

class OptionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ option }) {
    const { isMulti, value, onChange } = this.props;

    if (!isMulti) {
      return onChange(option.value);
    }

    const valueSet = new Set(value);

    if (valueSet.has(option.value)) {
      valueSet.delete(option.value);
    } else {
      valueSet.add(option.value);
    }

    return onChange([...valueSet]);
  }

  render() {
    if (!this.props.options) {
      return null;
    }

    let { options, showEmptyOption = false } = this.props;

    const emptyOption = { label: "None", value: null };
    
    if (showEmptyOption) {
      options.push(emptyOption);
    }
    
    const value = this.props.value === null ? undefined : this.props.value

    return (
      <Select
        value={value}
        options={this.props.options}
        labelKey="label"
        valueKey="value"
        placeholder={this.props.placeholder}
        onChange={this.handleChange}
        disabled={this.props.isDisabled}
        multiple={this.props.isMulti}
        closeOnChange={!this.props.isMulti}
        plain={this.props.plain}
        background="light-2"
      />
    );
  }
}

OptionSelector.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showEmptyOption: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  plain: PropTypes.bool
};

export default observer(OptionSelector);
