import React from "react";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import Moment from "react-moment";
import { Box, Heading, Text, Image, Layer } from "grommet";

import ensureStore from "../store";

import Metric from "../components/Metric";
import MetricRow from "../components/MetricRow";
import ListHeader from "../components/ListHeader";
import StatusIndicator from "../components/StatusIndicator";
import SuggestionsInput from "../components/SuggestionsInput";
import CloseButton from "../components/Buttons/CloseButton";

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.store = ensureStore();
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleUpdateEmployee = this.handleUpdateEmployee.bind(this);

    autorun(() => {
      if (this.store.selectedCheckoutId) {
        this.store.checkouts.fetchById(this.store.selectedCheckoutId);
      }
    });
  }

  handleUpdateEmployee(checkout, name) {
    checkout.updateEmployee(name);
  }

  handleDismiss() {
    this.store.setSelectedCheckoutId(null);
  }

  renderBasket({ basket, currencyCode }) {
    const items = basket.items.map(
      ({ quantity, shippedQuantity, articleNumber, pricePerItem }) => {
        const sku = this.store.products.skus.get(articleNumber);

        const name = sku && sku.product ? sku.product.name.defaultValue : null;
        const image = sku && sku.product ? sku.product.previewImage : null;

        const imageUrl = image
          ? image.url
          : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

        return (
          <Box
            key={articleNumber}
            direction="row"
            background="white"
            margin={{ bottom: "hair" }}
            justify="between"
            align="center"
          >
            <Box direction="row">
              <Box height="xsmall" width="xsmall" background="light-1">
                <Image src={imageUrl} fit="contain" />
              </Box>
              <Box pad="medium" gap="xxsmall">
                <Heading level={3}>{name || ""}</Heading>
                <Heading level={4}>{articleNumber}</Heading>
              </Box>
            </Box>
            <MetricRow>
              <Metric value={quantity} plain />
              <Metric value={shippedQuantity} plain />
              <Metric value={pricePerItem} unit={currencyCode} plain />
            </MetricRow>
          </Box>
        );
      }
    );

    return (
      <Box>
        <ListHeader
          metricHeaders={[
            { label: "Ordered Quantity", key: "orderedQuantity" },
            { label: "Shipped Quantity", key: "shippedQuantity" },
            { label: "Item Price", key: "pricePerItem" }
          ]}
        />
        {items}
      </Box>
    );
  }

  renderError({ hasError, errorMessage }) {
    if (!hasError) {
      return null;
    }

    return (
      <Box background={{ color: "white" }} pad="medium">
        <Heading level={2}>Error:</Heading>
        {errorMessage}
      </Box>
    );
  }

  render() {
    const checkout = this.store.selectedCheckout;

    if (!checkout) {
      return null;
    }

    const {
      id,
      status,
      screen,
      paymentStatus,
      orderStatus,
      externalOrderId
    } = checkout;

    return (
      <React.Fragment>
        <Layer
          position="center"
          onClickOutside={this.handleDismiss}
          onEsc={this.handleDismiss}
        >
          <Box direction="column" gap="small" width="80vw" elevation="small">
            <Box
              background="white"
              pad={{ top: "medium", horizontal: "medium" }}
              gap="small"
              direction="row"
              justify="between"
              align="start"
            >
              <Box direction="row" align="start">
                <Box margin={{ top: "small", right: "medium" }}>
                  <StatusIndicator status={status} />
                </Box>
                <Box>
                  <Heading level={2} size="medium">
                    {screen ? screen.name : checkout.screenId}
                  </Heading>
                  <Text size="small">
                    <Moment format="LLL">{checkout.createdAtUtc}</Moment>
                  </Text>
                  <Box width="medium" margin={{ top: "medium" }}>
                    <Text>
                      <strong>Staff:</strong>
                    </Text>
                    <SuggestionsInput
                      value={checkout.employee ? checkout.employee.name : null}
                      suggestions={this.store.checkouts.employeeNames}
                      onChange={name =>
                        this.handleUpdateEmployee(checkout, name)
                      }
                    />
                  </Box>
                  <Box
                    width="large"
                    margin={{ top: "medium", bottom: "none" }}
                  >
                  <Text>
                      <strong>Checkout ID:</strong> {id}
                    </Text>
                  <Text>
                      <strong>Order ID:</strong> {externalOrderId || "none"}
                    </Text>
                    <Text>
                      <strong>Payment:</strong> {paymentStatus}
                    </Text>
                    <Text>
                      <strong>Status:</strong> {orderStatus}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <CloseButton onPress={this.handleDismiss} />
            </Box>
            {this.renderError(checkout)}
            {this.renderBasket(checkout)}
          </Box>
        </Layer>
      </React.Fragment>
    );
  }
}

export default observer(Checkout);
