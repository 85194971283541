import React from "react";
import { observer } from "mobx-react";
import { Box, Heading, Text } from "grommet";

import ensureStore from "../store";

import ListHeader from "../components/ListHeader";
import DateRangePicker from "../components/DateRangePicker";
import ChartCard from "../components/Charts/ChartCard";
import PieChart from "../components/Charts/PieChart";
import BarChart from "../components/Charts/BarChart";
import CheckoutSummary from "../components/CheckoutSummary";
import Screens from "../components/Screens";

import mapToList from "../lib/mapToList";

class Insights extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelectCheckout = this.handleSelectCheckout.bind(this);
    this.handleSelectMetric = this.handleSelectMetric.bind(this);
    this.handleFromDateChange = this.handleFromDateChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);

    this.state = {};
    this.store = ensureStore();
  }

  handleSelectMetric(sortKey, sortDescending) {
    this.store.screens.setSortKey(sortKey, sortDescending);
  }

  handleSelectCheckout(checkoutId) {
    this.store.setSelectedCheckoutId(checkoutId);
  }

  handleFromDateChange(fromDate) {
    this.store.setFromDate(fromDate);
    this.fetchData();
  }

  handleToDateChange(toDate) {
    this.store.setToDate(toDate);
    this.fetchData();
  }

  renderCheckouts() {
    return this.store.checkouts.sortedItems.map(checkout => (
      <CheckoutSummary
        key={checkout.id}
        checkout={checkout}
        onSelect={this.handleSelectCheckout}
      />
    ));
  }

  renderCheckoutStats() {
    const { stats } = this.store;

    return (
      <Box
        background="white"
        direction="row"
        pad={{ vertical: "small", horizontal: "medium" }}
        justify="between"
        gap="small"
      >
        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Sessions
          </Text>
          <Heading level={3}>{stats.sessions.all.count}</Heading>
        </Box>

        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Baskets
          </Text>
          <Heading level={3}>{stats.sessions.all.hasBasket}</Heading>
        </Box>

        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Abandoned Baskets
          </Text>
          <Heading level={3}>
            {Math.round(stats.sessions.abandonedBasketRate * 100)}%
          </Heading>
        </Box>

        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Checkouts
          </Text>
          <Heading level={3}>{stats.checkouts.confirmed.count}</Heading>
        </Box>

        <Box border={{ side: "right" }} fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Orders
          </Text>
          <Heading level={3}>{stats.checkouts.ordered.count}</Heading>
        </Box>

        <Box fill="horizontal">
          <Text size="small" style={{ textTransform: "uppercase" }}>
            Failed Checkouts
          </Text>
          <Heading level={3}>{stats.checkouts.failed.count}</Heading>
        </Box>
      </Box>
    );
  }

  render() {
    if (!this.store.identity.isSignedIn) {
      return null;
    }
    
    const {
      sessions,
      checkouts,
      sessionHistogram,
      screenSessions,
      screenCheckouts,
      staffCheckouts,
      isLoading
    } = this.store.stats;

    const dataOptions = [
      {
        key: "order-status",
        label: "Order Status",
        data: mapToList(checkouts.ordered.orderStatus)
      },
      {
        key: "payment-status",
        label: "Payment Status",
        data: mapToList(checkouts.ordered.paymentStatus)
      },
      {
        key: "payment-method",
        label: "Payment Method",
        data: mapToList(checkouts.ordered.paymentMethod)
      },
      {
        key: "screen-checkouts",
        label: "Screen Checkouts",
        data: mapToList(screenCheckouts).map(({ value: { name, confirmed } }) => {
          return { key: name, value: confirmed.count };
        })
      },
      {
        key: "screen-sessions",
        label: "Screen Sessions",
        data: mapToList(screenSessions).map(({ value: { name, all } }) => {
          return { key: name, value: all.count };
        })
      },
      {
        key: "screen-checkout-value-eur",
        label: "Checkout Value - EUR",
        data: mapToList(screenCheckouts).map(
          ({ value: { name, confirmed } }) => {
            return { key: name, value: Math.round(confirmed.totalPriceEUR) };
          }
        )
      },
      {
        key: "screen-checkout-value-sek",
        label: "Checkout Value - SEK",
        data: mapToList(screenCheckouts).map(
          ({ value: { name, confirmed } }) => {
            return { key: name, value: Math.round(confirmed.totalPriceSEK) };
          }
        )
      },
      {
        key: "screen-checkout-value-usd",
        label: "Checkout Value - USD",
        data: mapToList(screenCheckouts).map(
          ({ value: { name, confirmed } }) => {
            return { key: name, value: Math.round(confirmed.totalPriceUSD) };
          }
        )
      },
      {
        key: "menu-categories",
        label: "Menu Categories",
        data: mapToList(sessions.all.selectedMenuItems)
      },
      {
        key: "currency",
        label: "Currency",
        data: mapToList(checkouts.confirmed.currencyCode)
      },
      {
        key: "delivery-country",
        label: "Delivery Country",
        data: mapToList(checkouts.confirmed.deliveryCountryCode)
      },
      {
        key: "staff",
        label: "Staff",
        data: mapToList(staffCheckouts).map(({ key, value: { count } }) => {
          return { value: count, key };
        })
      }
    ];

    const histogramOptions = [
      {
        key: "weekly-sessions",
        label: "Weekly Sessions",
        data: sessionHistogram.map(({ weekOfYear, global }) => {
          const result = {
            weekOfYear: `${weekOfYear}`,
            global: global.all.count
          };

          return result;
        }),
        keys: ["global"],
        isDefault: true
      }
    ];

    return (
      <React.Fragment>
        <Box
          direction="column"
          background={{ color: "light-2" }}
          pad={{ horizontal: "xlarge", vertical: "medium" }}
          gap="small"
        >
          <Box
            direction="row-responsive"
            align="center"
            justify="between"
            margin={{ bottom: "xsmall" }}
          >
            <Box />
            <Box
              direction="row-responsive"
              gap="medium"
              margin={{ top: "xsmall" }}
            >
              <DateRangePicker
                value={this.store.fromDate}
                onDateChange={this.handleFromDateChange}
                maxDate={this.store.toDate}
              />
              <DateRangePicker
                value={this.store.toDate}
                onDateChange={this.handleToDateChange}
                minDate={this.store.fromDate}
              />
            </Box>
          </Box>
          <Box
            direction="column"
            pad={{ top: "medium", bottom: "xlarge" }}
            alignSelf="end"
            align="end"
          >
            <Box direction="row">
              <Heading
                level={1}
                size="large"
                style={{ fontWeight: "600", letterSpacing: "-0.025em" }}
              >
                {Math.round(checkouts.confirmed.totalPriceSEK) || 0}
              </Heading>
              <Heading
                level={1}
                size="large"
                style={{ fontWeight: "300", letterSpacing: "-0.033em" }}
              >{`\u00a0SEK`}</Heading>
            </Box>
          </Box>
          <ListHeader
            onSelectMetric={this.handleSelectMetric}
            isLoading={isLoading}
          />

          {this.renderCheckoutStats()}

          <Box direction="row" gap="small">
            <ChartCard
              isLoading={isLoading}
              dataOptions={dataOptions}
              defaultOption="screen-checkouts"
              labelName="key"
            >
              <PieChart id="analytics-pie-chart-1" />
            </ChartCard>
            <ChartCard
              isLoading={isLoading}
              dataOptions={dataOptions}
              defaultOption="menu-categories"
              labelName="key"
            >
              <PieChart id="analytics-pie-chart-1" />
            </ChartCard>
          </Box>

          <Box>
            <ChartCard
              isLoading={isLoading}
              dataOptions={histogramOptions}
              defaultOption="weekly-sessions"
              labelName="key"
            >
              <BarChart id="analytics-histogram-chart" />
            </ChartCard>
          </Box>

          <Box>
            <ListHeader
              isLoading={null}
              onSelectMetric={this.handleSelectMetric}
              metricHeaders={[
                {
                  label: "Sessions",
                  sortKey: "sessionCount",
                  sortDescending: true
                },
                {
                  label: "Baskets",
                  sortKey: "basketCount",
                  sortDescending: true
                },
                {
                  label: "Checkouts",
                  sortKey: "confirmedCheckoutCount",
                  sortDescending: true
                },
                {
                  label: "Orders",
                  sortKey: "orderCount",
                  sortDescending: true
                },
                {
                  label: "Failed Checkouts",
                  sortKey: "failedCheckoutCount",
                  sortDescending: true
                }
              ]}
            />
            <Screens
              screens={this.store.screens.sortedItems}
              sortKey={this.store.screens.sortKey}
              sortDescending={this.store.screens.sortDescending}
            />
          </Box>

          <Box margin={{ top: "xlarge" }}>
            <Heading
              level={1}
              size="large"
              style={{ fontWeight: "400", letterSpacing: "-0.025em" }}
            >
              Checkouts
            </Heading>
            <ListHeader
              isLoading={isLoading}
              onSelectMetric={this.handleSelectMetric}
              metricHeaders={[
                { label: "Staff", sortKey: "employee", sortDescending: true },
                {
                  label: "Ordered Quantity",
                  sortKey: "orderedQuantity",
                  sortDescending: true
                },
                {
                  label: "Shipped Quantity",
                  sortKey: "shippedQuantity",
                  sortDescending: true
                },
                { label: "Value", sortKey: "totalPrice", sortDescending: true }
              ]}
            />
            <Box gap="hair">{this.renderCheckouts()}</Box>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default observer(Insights);
