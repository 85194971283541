import { types, getRoot, getEnv, flow } from "mobx-state-tree";

export default types
  .model("User", {
    id: types.identifier,
    email: types.string,
    password: types.maybeNull(types.string),
    isAdmin: types.optional(types.boolean, false),
    customerId: types.maybeNull(types.string)
  })
  .actions(self => ({
    changePassword(oldPassword, newPassword, throwException = false) {
      const { cancellationToken, cancel } = getEnv(
        self
      ).connector.getCancellationTokenSource();

      const action = flow(function* fetch() {
        yield getEnv(self).connector.changeUserPassword(
          self.id,
          oldPassword,
          newPassword,
          cancellationToken
        );
        getRoot(self).createNotification(
          `Password for ${self.email} has been changed`
        );

        // Forget the old (initial) password
        self.password = null;
      });

      return getRoot(self).runAction(
        `changeUserPassword-${self.id}`,
        action,
        cancel,
        throwException
      );
    }
  }));
